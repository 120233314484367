import React from 'react'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import { Link } from 'react-router-dom';
import {Email} from 'react-obfuscate-email'
// import { ReactSVG } from 'react-svg'
import {ReactComponent as Phone} from '../../../icon/phone.svg'
import {ReactComponent as Emailme} from '../../../icon/mail.svg'

const GetFooterAcf = gql`
    query GetFooter{
      footer {
        footer {
          city
          email
          facebook
          fieldGroupName
          getDirections
          instagram
          linkedin
          phoneNumber
          postalCode
          province
          street
          tikTok
          twitter
          youTube
        }
      }
    }
`;

function Footer() {

  const { loading, error, data } = useQuery(GetFooterAcf);
  if (loading) {
    return (<h4 className="padding">Loading Footer...</h4>)
  }
  if (error) {
    return (<h4 className="padding">Something went wrong...</h4>)
  }
  // console.log(data)
  return (
    <>
      <footer id='footer'>
        <div id="contact" className="contact padding-lr">
          <div className="contact-info w-1920">
            <h1>Contact</h1>
            <div className="details" key="footer-contact">
              <div className='contact'>
                <Link to={`tel:${data.footer.footer.phoneNumber}`}><Phone />{data.footer.footer.phoneNumber}</Link>
                <Email email={data.footer.footer.email}><Emailme />{data.footer.footer.email}</Email>
              </div>
              <div className="address-details">
                  <p>{data.footer.footer.street}, {data.footer.footer.city}</p>
                  <p>{data.footer.footer.province} {data.footer.footer.postalCode}</p>
                  <Link to={data.footer.footer.getDirections} target="_blank" rel="noopener noreferrer">Get Directions</Link>
              </div>
            </div>
            <div className='social-media'>
              <Link to={data.footer.footer.facebook} target="_blank" rel="noopener noreferrer">Facebook</Link>
              <Link to={data.footer.footer.instagram} target="_blank" rel="noopener noreferrer">Instagram</Link>
              <Link to={data.footer.footer.twitter} target="_blank" rel="noopener noreferrer">Twitter</Link>
              <Link to={data.footer.footer.youTube} target="_blank" rel="noopener noreferrer">YouTube</Link>
              <Link to={data.footer.footer.tikTok} target="_blank" rel="noopener noreferrer">TikTok</Link>
              <Link to={data.footer.footer.linkedin} target="_blank" rel="noopener noreferrer">LinkedIn</Link>
            </div>
          </div>
          <div className="copyright center">
            <span className="copyright-text">&copy; React Website {new Date().getFullYear()} . </span>
            <span className="site-by">Site by <a href="https://psone.ca/" target="_blank" rel="noopener noreferrer">PSOne</a></span>
            <span> | <a href="https://policies.google.com/technologies/partner-sites" target="_blank" rel="noopener noreferrer">Google Terms</a></span>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer