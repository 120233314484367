import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const Single = () => {
    const {id} =useParams();
    var [post, setPost] = useState({});
    let url = `${process.env.REACT_APP_API_ROOT}/posts/${id}`;
    useEffect(() => {
        axios.get(url).then((response) => {
            console.log(response.data);
            setPost(response.data);
        });
    }, [url]);
  return (
    <>
        {
            Object.values(post).length ? (
                <div class="padding">
                    <h2 className='text-lg font-bold'>{post.title.rendered}</h2>
                    <p dangerouslySetInnerHTML={{__html: post.content.rendered}} ></p>
                </div>
            ): 'loading'
        
        }
    </>
  )
}

export default Single