import { configureStore } from '@reduxjs/toolkit'
import authReducer from './slice/authSlice'
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const presistConfig = {
    key: 'iws', 
    storage
}

const persistAuthReducer = persistReducer(presistConfig, authReducer)
export const store = configureStore({
    reducer: {
        auth: persistAuthReducer
    }
});

export const persistedStore = persistStore(store);