import React from 'react'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import { useParams } from 'react-router-dom';

const GetSingleHotel = gql`
  query GetSingle($hotelId: ID!) {
    hotel(id: $hotelId, idType: DATABASE_ID) {
      title
      hotelListing {
        content
        hotelBathroom
        hotelBedrooms
        swimmingPool
      }
      featuredImage {
        node {
          altText
          sourceUrl
        }
      }
    }
  }
`;

const SingleHotel = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(GetSingleHotel, {
    variables: { hotelId: id },
  });
  if (loading) {
    return (<h4 className="padding">Loading Home Page...</h4>)
  }
  if (error) {
    return (<h4 className="padding">Something went wrong...</h4>)
  }
  const hotel = data?.hotel; // ?: is a optional chaining operator, data?.hotel checks if the data object exists and has a hotel property. 
  console.log(data)
  return (
    <div>
      <div className="about-section" key="about" id='about'>
        <div className="w-1920 container">
          <div className="div-left">
            <h2>{hotel?.title}</h2>
            <p className="bathrooms"><strong>Bedromm:</strong> {hotel?.hotelListing.hotelBedrooms}</p>
            <p className="bathrooms"><strong>Bathroom:</strong> {hotel?.hotelListing.hotelBathroom}</p>
            {
              (hotel?.hotelListing.swimmingPool)
              ? <p>Swimming Pool is included</p>
              : <p>Not Included Swimming Pool</p>
            }
            <p>{hotel?.hotelListing.content}</p>
          </div>
          <div className="div-right">
          <img src={hotel?.featuredImage.node.sourceUrl} alt={hotel?.featuredImage.node.altText} class="img-responsive hotel-feature-image" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SingleHotel