import React from 'react'
import { ReactComponent as Logo } from '../../../logo.svg'
// import { Link } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { useDispatch, useSelector } from 'react-redux';

const GetMenuItems2 = gql `
  query GetMenu{
    menus {
      nodes {
        name
        menuItems {
          edges {
            node {
              label
              url
            }
          }
        }
      }
    }
  }
`;

const Overlay = ({ display, setDisplay }) => {
  // const auth = localStorage.getItem('user');
  const dispatch = useDispatch();
  const authUser  = useSelector((state)=>state.auth.user);
  const toggleClick = () => {
    setDisplay(true)
  }
  // console.log(auth);
  const { loading, error, data } = useQuery(GetMenuItems2);
  if (loading) {
    return (<h4 className="padding">Loading menu...</h4>)
  }
  if (error) {
    return (<h4 className="padding">Something went wrong...</h4>)
  }
  return (
    <>
      <div className={`overlay ${display ? 'open' : ''}`} id="overlay">
        <a className="site-logo" href="/" rel="home" aria-label="react demo">
          <Logo />
          {/* {{source (directory ~ '/static/images/logo/tricor-color-logo.svg') }} */}
        </a>
        <div className="overlay-nav-main">
          <nav id="site-navigation3" className="overlay-menu" role="navigation">
            <div className='p-5'>
              <ul className="navbar flex gap-5 justify-end">
                {
                  data.menus.nodes && data.menus.nodes.map((menu, key) => {
                    return (
                      <>
                        {
                          menu.menuItems.edges.map((edge, key) => {
                            // console.log(edge.node.label);
                            return (
                              <li key={key} onClick={() => toggleClick()}><Link to={edge.node.url}>{edge.node.label}</Link></li>
                            )
                          })
                        }
                      </>
                    )

                  })
                }

              </ul>
            </div>
          </nav>
        </div>
      </div>
    </>
  )
}

export default Overlay