import React from 'react'
import { Routes, Route} from 'react-router-dom';
import Home from './Home';
import Posts from './Posts';
import Navbar from './common/Navbar';
import Login from './Login';
import AddPost from './AddPost';
import NotFound from './NotFound';
import Single from './Single';
import Hotel from './Hotel';
import SingleHotel from './Hotel/Single';

const Pages = () => {
  return (
    <>
        <Routes>
            <Route path='/' element={<Home />}/>
            <Route path='/blog' element={<Posts />}/>
            <Route path='/hotels' element={<Hotel />}/>
            <Route path='/blog/:id' element={<Single />}/>
            <Route path='/hotels/:id' element={<SingleHotel />}/>
            <Route path='/login' element={<Login />}/>
            <Route path='/addpost' element={<AddPost />}/>
            <Route path='*' element={<NotFound />}/>
        </Routes>
    </>
  )
}

export default Pages