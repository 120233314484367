import React from 'react'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'
// slick-slider
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const GetHomeAcf = gql`
query GetHome{
  pages {
    nodes {
      home {
        titleOfPage
        heroImage {
          altText
          sourceUrl
        }
        services {
          serviceDetails
          serviceName
          icon {
            altText
            sourceUrl
          }
        }
        aboutSection {
          image {
            altText
            sourceUrl
          }
          content
          title
        }
        fullImageHome {
          altText
          sourceUrl
        }
        gallery {
          image {
            altText
            sourceUrl
          }
          caption
        }
        galleryContent
        galleryTitle
      }
    }
  }
}`;
const Home = () => {
  var settings = {
    dots: true,
    arrow: false,
    speed: 800,
    fade: true,
    cssEase: "ease",
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1
  }
  const { loading, error, data } = useQuery(GetHomeAcf);
  if (loading) {
    return (<h4 className="padding">Loading Home Page...</h4>)
  }
  if (error) {
    return (<h4 className="padding">Something went wrong...</h4>)
  }
  return (
    <div>
      {
        data.pages.nodes && data.pages.nodes.map((page, key) => {
          return (
            <>
              <div className="hero-section" key="hero">
                <img src={page.home.heroImage.sourceUrl} alt={page.home.heroImage.altText} className="img-responsive" />
                <div className="inner">
                  <h1>{page.home.titleOfPage}</h1>
                </div>
              </div>
              {/* about-section */}
              <div className="about-section" key="about" id='about'>
                <div className="w-1920 container">
                  <div className="div-left">
                    <h2>{page.home.aboutSection.title}</h2>
                    <p>{page.home.aboutSection.content}</p>
                  </div>
                  <div className="div-right">
                    <img src={page.home.aboutSection.image.sourceUrl} alt={page.home.aboutSection.image.altText} className="img-responsive" />
                  </div>
                </div>
              </div>
              {/* service-section */}
              <div className="service-section" key="services" id='services'>
                  <h2 className='center' style={{marginBottom: '80px', color: '#c70909'}}>Services</h2>
                <div className="w-1920 container">
                  {page.home.services.map((service, key) => {
                    return (
                      <div className="service" key={key}>
                        <img src={service.icon.sourceUrl} alt={service.icon.altText} />
                        <h3>{service.serviceName}</h3>
                        <p>{service.serviceDetails}</p>
                      </div>
                    )
                  })}
                </div>
              </div>
              {/* Full image */}
              <div className="fullImage">
                <img src={page.home.fullImageHome.sourceUrl} alt={page.home.fullImageHome.altText} className="img-responsive" />
              </div>
              {/* gallery section */}
              <div className="gallery-section" key="gallery" id='gallery'>
                <div className="w-1920 container">
                  <div className="galleryintro">
                    <h2>{page.home.galleryTitle}</h2>
                    <p>{page.home.galleryContent}</p>
                  </div>                          
                  <Slider {...settings} className='galley-slider-div'>
                    {page.home.gallery.map((gal, key) => {
                      return (
                          <div className="gallery" key={key}>
                            <img src={gal.image.sourceUrl} alt={gal.image.altText} />
                            <p className="caption">{gal.caption}</p>
                          </div>
                      )
                    })}
                  </Slider>
                </div>
              </div>
            </>
          )
        })
      }
    </div>
  )
}

export default Home
