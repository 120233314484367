import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { HashLink as Link } from 'react-router-hash-link';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';

const GetMenuItems = gql `
  query GetMenu{
    menus {
      nodes {
        name
        menuItems {
          edges {
            node {
              label
              url
            }
          }
        }
      }
    }
  }
`;

const NavBar = () => {
  // const auth = localStorage.getItem('user');
  const dispatch = useDispatch();
  const authUser  = useSelector((state)=>state.auth.user);
  // console.log(auth);
  const { loading, error, data } = useQuery(GetMenuItems);
  if (loading) {
    return (<h4 className="padding">Loading menu...</h4>)
  }
  if (error) {
    return (<h4 className="padding">Something went wrong...</h4>)
  }
  // console.log(data)
  return (
    <div className='p-5'>
      <ul className="navbar flex gap-5 justify-end">
        {
          data.menus.nodes && data.menus.nodes.map((menu,key)=>{
            return(
              <>
              {
                  menu.menuItems.edges.map((edge, key)=>{
                    // console.log(edge.node.label);
                    return(
                      <li key={key}><Link to={edge.node.url}>{edge.node.label}</Link></li>
                    )
                  })
              }
              </>
            )
                
          })
        }

      </ul>
    </div>
  )
}

export default NavBar