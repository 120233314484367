import React, { useState } from 'react'
import Overlay from './Overlay'

const Hemburger = () => {
    const[display, setDisplay] = useState(false)
  return (
    <>
        <div className={`button_container ${display? 'active': ''}`} id="toggle" onClick={()=>setDisplay(!display)}>
            <span className="top"></span>
            <span className="middle"></span>
            <span className="bottom"></span>
        </div>
        <Overlay display={display} setDisplay = {()=>{setDisplay(!display)}} />
    </>
  )
}

export default Hemburger