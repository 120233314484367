import React from 'react'
// import logo from './logo.svg';
import { ReactComponent as Logo } from '../../../logo.svg'
import Navbar from './Navbar'
import { Link } from 'react-router-dom';
import Hemburger from './Hemburger';
import Overlay from './Overlay';

const Header = () => {
    return (
        <>
            <header className="App-header">
                <div className="w-1920">
                    {/* <a class="site-logo" href="site.url" rel="home" aria-label="site.name"> */}
                    <Link to="/" className='site-logo'>
                        {/* <img src={logo} alt='mysvgImage' /> */}
                        <Logo />
                    </Link>
                    {/* </a> */}
                    <nav id="site-navigation" className="nav-main" role="navigation">
                        <Navbar />
                    </nav>
                </div>
            </header>
            <Hemburger />
        </>
    )
}

export default Header