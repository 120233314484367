import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom';

const Posts = () => {
    // var posts = [];
    var [posts, setPosts] = useState([]);
    let url = `${process.env.REACT_APP_API_ROOT}/posts`;
    useEffect(() => {
        axios.get(url).then((response) => {
            // console.log(response.data);
            setPosts(response.data);
        });
    }, [url]);
    console.log('posts', posts);

    const wp_query = {
        query: `{
            posts{
                edges{
                    node{
                        title
                        content
                    }
                }
            }
        }`
    }
    
    return (
        <>
            {/* javascript should written in curly braces when write inside return statement */}
            <div className=' padding w-4/5 m-auto flex justify-between align-middle flex-wrap gap-10 '>
                {
                    posts && posts.map((post) => {
                        // console.log(post.content.rendered);
                        return (
                            <div key={post.id} className="card shadow-lg p-3 w-96">
                                <Link to={`${post.id}`}>
                                    <h2 className='text-lg font-bold'>{post.title.rendered}</h2>
                                    <p dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}></p>
                                    {/* <p dangerouslySetInnerHTML={{__html: post.content.rendered}}></p>  */}
                                </Link>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}

export default Posts;