import { Link } from "react-router-dom";

export default function NotFound() {
    return (
        <div className="error-block">
            <h1 style={{marginBlock: '50px'}} className="center padding-lr">404</h1>
            <h3 className="page center padding-lr">Ooops!!! The page you are looking for is not found</h3>
            {/* <a className="back-home" href="{{site.url}}">Back to home</a> */}
            <Link className="back-home" to='/'>Back to home</Link>
            {/* <Link to='/blog'>Blog</Link>
            <Link to='/contact'>Contact</Link> */}
        </div>
    )
}