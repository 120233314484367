import './App.css';
import Header from './components/pages/common/Header';
import Pages from './components/pages/Pages';
import Footer from './components/pages/common/Footer';
const App = () => {
    return (
        <div className="App">
           <Header />
            <section id="content" role="main" className="content-wrapper">
                <div className="wrapper">                    
                    <Pages />
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default App;
