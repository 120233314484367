import React from 'react'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import { Link } from 'react-router-dom';

const GetHotels = gql`
  query GetHotel {
    hotels {
      edges {
        node {
          hotelId
          title
          featuredImage {
            node {
              altText
              sourceUrl
            }
          }
          hotelListing {
            hotelBathroom
            hotelBedrooms
            swimmingPool
            content
          }
        }
      }
    }
  }
`;

const Hotel = () => {
  const { loading, error, data } = useQuery(GetHotels);
  if (loading) {
    return (<h4 className="padding">Loading Home Page...</h4>)
  }
  if (error) {
    return (<h4 className="padding">Something went wrong...</h4>)
  }
  return (
    <div className=' padding w-4/5 m-auto flex align-middle flex-wrap gap-10 all-hotels'>
      {
        data.hotels.edges && data.hotels.edges.map((hotel, key) => {
            const { hotelId, title, featuredImage, hotelListing } = hotel.node;
          return (
            <>
              <div key={hotelId} className="card shadow-lg p-3 w-96">
                <Link to={`/hotels/${hotelId}`} className="hotel-link">
                  <img src={featuredImage.node.sourceUrl} alt={featuredImage.node.altText} class="img-responsive hotel-feature-image" />
                  <h2 className='text-lg font-bold'>{title}</h2>
                  {
                    (hotelListing.content.length > 100)
                      ? <p dangerouslySetInnerHTML={{ __html: hotelListing.content.substring(0, 100) + '...' }}></p>
                      : <p dangerouslySetInnerHTML={{ __html: hotelListing.content }}></p>
                  }

                </Link>
              </div>
            </>
          )
        })
      }
    </div>
  )
}

export default Hotel